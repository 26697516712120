
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>

     <validation-observer ref="simpleRules">
       <b-form
          @submit.prevent
        >

      <b-card title="Informations générales">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom du produit"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <br />
            <b-form-group
              label="Nom de marque"
              label-for="basicInput"
            >
           
              <b-form-input
                id="basicInput"
                v-model="form.brand_name"
                :plaintext="readOnly"
                placeholder="Nom de la marque"
               
              />
             
            </b-form-group>
            <br />
          

          </b-col>

        
        
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

      

         
        </b-row>
        <b-row>
          <b-col
            md="12"
            xl="12"
            class="mb-1"
          >
         
            <label for="textarea-default">Description</label>
            <b-form-textarea
              v-model="form.description"
              id="textarea-default"
              placeholder="Description produit"
            
              rows="3"
            />

          
          </b-col>
        </b-row>
        
         

      </b-card>

    

   </b-form>

  </validation-observer>




    <b-button 
     @click.prevent="updateGlass"
    >
    Suggérer les modifications
    </b-button>
  </div>
  <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteModelPart(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer ce model part ?
          </b-card-text>
        </b-modal>
  
  
      </b-overlay>

 

</template>

<script>
import { BButton,BFormInput, BRow, BForm, BCol,BOverlay, BFormGroup,BFormFile, BCard, BCardText, BMedia, BImg, BFormCheckbox, BFormTextarea, BSpinner} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils,Auth} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'


export default {
  name: 'GlassForm',
  components: {
    BButton,
    BCardText,
    BMedia, 
    BOverlay,
    BImg,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BSpinner,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
        selectedItem:null,
          loading:true,
          
          initialForm:{
            name:"",
           
           brand_name:"",
          
           description:'',
           price:0,
           
          },
          form:{
            name:"",
           
            brand_name:"",
           
            description:'',
            price:0,
            
          },
         
      }
  },
  computed: {
   
  },
  filters: {
      
  },
  
  watch:{
     
  },
 
  props: [
    'glass', 
    'readOnly', 
    'glass_textures',
    'glass_colors',
    'glass_tints',
    'glass_branchs',
    'glass_glass_options',
    'glass_glass_types',
    'glass_refinements',
    'glass_processings',
    'glass_photos'
    ],
  methods:{
   
   
      async updateGlass()
      {
         this.$refs.simpleRules.validate().then(async success => {
          if (success) {
         
          

          try {

            this.loading=true
        
            var datas={}
            datas.name=this.form.name
            datas.price=this.form.price
            datas.brand_name=this.form.brand_name
            datas.description=this.form.description
            
            datas.glass={id:this.glass.id}

            let edit_suggest = await APIRequest.create('edit-suggests',datas)
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'La suggestion a bien été envoyée !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'glasses'})
          } catch (error) {
            console.log("error")
            console.log(error)
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
         })
      },
      
      
  },
  async mounted(){

   

    if(this.glass!=null)
    {

     
    
      this.form=this.glass
      
      
      this.edition=true
      
    }
    this.loaded=true
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>